@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
.anton-font {
  font-family: 'Anton', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@600&display=swap');
.source-serif-pro-semibold {
  font-family: 'Source Serif Pro', serif;
}


@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap');
.barlow {
  font-family: 'Barlow', sans-serif;
}


@font-face {
  font-family: "Barlow-SemiBold";
  src: local("Barlow-SemiBold"), url("./utils/fonts/Barlow-SemiBold.ttf") format("truetype");
}
.barlow-semibold {
  font-family: 'Barlow-SemiBold', sans-serif;
}

@font-face { font-family: "AFBookRounded"; src: url("./utils/fonts/ag-book-rounded.otf") format("opentype"); }
.ag-book-rounded {
  font-family: 'AFBookRounded';
}


@font-face {
  font-family: "Averta";
  src: url("./utils/fonts/Averta/Averta-Bold.otf");
}
@font-face {
  font-family: "Averta bold";
  src: url("./utils/fonts/Averta/Averta-ExtraBold.otf");
}


@font-face {
  font-family: "Arial Rounded MT Bold";
  src: local("Arial Rounded MT Bold"), url(./utils/fonts/arlrdbd.ttf) format("truetype");
}







* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}


html {
  scroll-behavior: smooth;
}


body {
  @apply text-gray-700 dark:bg-gray-800 dark:text-gray-100;
  font-family: "Inter", sans-serif;
}






::-webkit-scrollbar-thumb {
  @apply bg-gray-400 bg-opacity-75 cursor-pointer dark:bg-gray-500 rounded-sm transition-colors duration-200
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500 bg-opacity-60 dark:bg-gray-600 transition-colors duration-200
}

::-webkit-scrollbar-track {
  @apply bg-gray-300 mx-2 transition-colors rounded-sm duration-200;
}

::-webkit-scrollbar {
  @apply w-2 h-2
}


textarea::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}





/* Search box */
.search-box {
  @apply w-full flex items-center overflow-hidden bg-gray-100 dark:bg-gray-600 border border-gray-300 dark:border-gray-500 hover:border-gray-500 dark:hover:border-gray-400 rounded-md transition-colors duration-200
}
.search-box:focus-within {
  outline: 2px solid rgba(59, 130, 246);
}
















.screensize {
  display: block;
  position: relative;
  width: 1920px !important;
  height: 1090px !important;
}
.logo-bg {
  position: relative;
  &::before {
    content: "";
    top: 48.65%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 2.3em;
    height: 2.3em;
    z-index: -1;
    border-radius: 5px;
  }
}


.textBoxClasses h1 {
  font-size: 25px;
}
.textBoxClasses h2 {
  font-size: 20px;
}
.textBoxClasses h3 {
  font-size: 18px;
}
.textBoxClasses ol {
  list-style-type: decimal;
  margin-left: 30px;
}
.textBoxClasses ul {
  list-style-type: disc;
  margin-left: 30px;
}
.textBoxClasses a {
  color: dodgerblue;
  text-decoration: underline;
}

.small {
  font-size: 1.2vw !important;
  line-height: 3vh!important;
}

.big {
  font-size: 2.2vw !important;
}


blockquote {
  font-weight: 700;
  font-family: 'Averta bold';
}
strong {
  font-weight: 700;
  font-family: 'Averta bold';
}


.textBoxClassesXXL h1 {
  font-size: 1.7vw;
}
.textBoxClassesXXL h2 {
  font-size: 1.7vw;
}
.textBoxClassesXXL h3 {
  font-size: 1.7vw;
}
.textBoxClassesXXL p {
  font-size: 1.7vw;
}
.textBoxClassesXXL ol {
  list-style-type: decimal;
  margin-left: 30px;
  font-size: 20px;
}
.textBoxClassesXXL ul {
  list-style-type: disc;
  margin-left: 30px;
  font-size: 20px;
}
.textBoxClassesXXL a {
  color: dodgerblue;
  text-decoration: underline;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.bottom-extra-space p:not(.small) {
  margin-bottom: 15px;
}

.textBoxClassesXXL p.line-small, 
.line-small {
  @apply text-gray-50;
  margin-bottom: 10px !important; 
  font-size: 1.7vw !important;
  line-height: 3vh!important;
}

.textBoxClassesXXL p.line-small ~ p { 
  font-size:  1.3vw !important;
  margin-bottom: 0px !important; 
} 




.font-guapa {
  font-family: "Arial Rounded MT Bold", "Arial", sans-serif;
}

.btn {
  @apply rounded-md inline-block pl-2 pr-3 py-1 bg-blue-500 text-gray-50 hover:bg-blue-600 font-semibold active:scale-95 duration-200;
}

.btn--transparent {
  @apply bg-opacity-0 text-gray-400 hover:bg-opacity-0 hover:text-gray-600;
}


.btn--gray {
  @apply bg-gray-200 text-gray-400 hover:bg-gray-300 hover:text-gray-600;
}



.btn--icon {
  @apply flex items-center space-x-2 cursor-pointer;
}




.btn--tool {
  @apply flex items-center space-x-1 cursor-pointer bg-gray-300 dark:bg-gray-600 bg-opacity-80 hover:text-gray-700 hover:bg-gray-300 rounded-md font-semibold text-gray-500 dark:text-white dark:hover:text-blue-500 disabled:opacity-50 disabled:pointer-events-none active:scale-90 duration-200 ease-in-out;
}

.btn--tool--gray {
  @apply flex items-center space-x-1 cursor-pointer text-sm bg-gray-300 bg-opacity-80 dark:bg-gray-800 hover:bg-gray-500 hover:bg-opacity-40 dark:hover:bg-gray-900 text-gray-500 hover:text-gray-700 active:scale-90 rounded-md font-semibold dark:text-gray-200 dark:hover:text-gray-200 disabled:opacity-50 disabled:pointer-events-none active:bg-gray-400 transition duration-200 ease-in-out;
}

.btn--tool--blue {
  @apply flex items-center space-x-1 cursor-pointer text-sm bg-blue-500 hover:bg-blue-600 text-gray-50 rounded-md font-semibold dark:text-gray-200 dark:hover:text-gray-200 active:scale-90 disabled:opacity-50 disabled:pointer-events-none shadow-md hover:shadow-lg active:bg-blue-800 transition duration-200 ease-in-out;
}

.btn--tool--red {
  @apply flex items-center space-x-1 cursor-pointer text-sm bg-red-500 hover:bg-red-600 text-gray-50 rounded-md font-semibold dark:text-gray-200 dark:hover:text-gray-200 active:scale-90 disabled:opacity-50 disabled:pointer-events-none shadow-md hover:shadow-lg active:bg-red-800 transition duration-200 ease-in-out;
}

.btn--tool--gray--basiccase {
  @apply flex items-center space-x-1 cursor-pointer text-sm bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-900 text-gray-500 hover:text-gray-700 active:scale-90 rounded-md font-semibold dark:text-gray-200 dark:hover:text-gray-200 disabled:opacity-50 disabled:pointer-events-none hover:shadow-lg active:bg-gray-400 transition duration-200 ease-in-out;
}

.btn--tool--blue--basiccase {
  @apply flex items-center space-x-1 cursor-pointer text-sm bg-blue-500 hover:bg-blue-600 text-gray-50 rounded-md font-semibold dark:text-gray-200 dark:hover:text-gray-200 active:scale-90 disabled:opacity-50 disabled:pointer-events-none shadow-md hover:shadow-lg active:bg-blue-800 transition duration-200 ease-in-out;
}




.btn-link {
  @apply flex items-center cursor-pointer space-x-2 font-semibold text-blue-500 hover:text-blue-700 active:text-indigo-700 duration-200
}

.btn-nav {
  @apply flex items-center space-x-2 px-3 py-2 rounded;
}

.field {
  @apply w-full flex items-center;
}

button {
  white-space: nowrap;
}

button[disabled] {
  @apply pointer-events-none opacity-50;
}




/* input:not(input[id*="rc-editable-input"]):not(.neutre),
select,
textarea:not(.neutre),
.inputStyle {
  @apply bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:active:bg-gray-800 px-2 transition duration-150 ease-in-out;
}

input:not(input[id*="rc-editable-input"]):not(.neutre) {
  @apply py-1;
}

input[disabled] {
  @apply text-gray-700;
}

textarea {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
} */



audio {
  &::-webkit-media-controls-enclosure {
    @apply dark:bg-gray-100 rounded-none;
    background-color: transparent;
  }
}

.audioMessage {
  &::-webkit-media-controls-enclosure {
    @apply rounded;
  }
}

.audioOverlay {
  &::-webkit-media-controls-enclosure {
    @apply bg-gray-100 rounded-none;
    background-color: transparent;
  }
  &::-webkit-media-controls-mute-button,
  &::-webkit-media-controls-current-time-display,
  &::-webkit-media-controls-time-remaining-display,
  &::-webkit-media-controls-volume-slider,
  &::-webkit-media-controls-volume-control-container {
    display: none !important;
  }
  &::-webkit-media-controls-timeline {
    background: white;
  }
  &::-webkit-media-controls-play-button {
  }
}


select {
  @apply py-0.5 pr-2;
}



.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.toggle-checkbox {
  @apply border-gray-300 dark:border-gray-600;
}

.toggle-checkbox:checked {
  @apply right-0 border-blue-300;
  right: 0;
}

.toggle-checkbox:checked + .toggle-label {
  @apply bg-blue-300;
}



.play-button {
  box-sizing: border-box;
  height: 1em;

  border-color: transparent transparent transparent currentColor;
  transition: 100ms all ease;
  will-change: border-width;
  cursor: pointer;

  border-style: solid;
  border-width: 0.5em 0 0.5em 0.75em;

  &.--pause {
    border-style: double;
    border-width: 0px 0 0px 0.75em;
  }
}

.items-end {
  align-items: flex-end;
}

.container_1920 {
  width: 1920px;
}

.container_1824 {
  width: 1824px;
}

.only-subtitles {
  color: white;
  margin-left: 48px; /* 3rem */
  margin-right: 48px; /* 3rem */
  margin-bottom: 3rem;
}

/* #root {
  background: inherit;
}

body {
  background: rgb(0, 255, 0);
} */

.m-auto {
  margin: auto;
}
.w-button {
  color: white;
  /* font-size: 1.5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .w-1/3 {
  width: 33.333333%;
}
.w-2/3 {
  width: 66.666667%;
} */
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}















